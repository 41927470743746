import VcsImg from "./assets/images/vcs.png";
import BcsImg from "./assets/images/beg-cs.jpeg";

import PathFindingImg from "./assets/images/project imgs/path-finding.PNG";
import SortingImg from "./assets/images/project imgs/sorting.PNG";
import LorukiImg from "./assets/images/project imgs/loruki.PNG";
import ReactFoodCartImg from "./assets/images/project imgs/react-food-cart.PNG";
import BlogImg from "./assets/images/project imgs/blog.jpg";
import EcommImg from "./assets/images/project imgs/ecomm.jpg";

export const experience = [
  {
    companyName: "Grameen Impact Ventures",
    location: "Gurgaon, HY",
    position: "Analyst Intern",
    time: "June 2021 - July 2021",
    description: [
      "Cleaned and Analyzed data related to training of the Grameen Mittra’s (local authority of organization)",
      "Automated the process of generating reports for frequently changing datasets",
      "Generated structured business reports using Google Data Studio",
      "Designed business presentable reports on data visualization dashboards like Grafana and Kibana",
    ],
  },
  {
    companyName: "Code Planet Technologies Pvt. Ltd.",
    location: "Jaipur, RJ",
    position: "Full Stack Developer Intern",
    time: "Nov 2019 - Nov 2020",
    description: [
      "Designed, developed and implemented readable, well documented and reusable APIs using Spring Boot",
      "Implemented a database in MySQL to store project resource information to drive resource allocation",
      "Built reusable UI components in React.js to digitize the process of examinations",
      "Mentored a 3 developers team on the company’s development and product stack",
    ],
  },
];

export const skills = [
  {
    category: "Languages",
    skills: [
      {
        title: "Java",
        icon: "fab fa-java",
      },
      {
        title: "Python",
        icon: "fab fa-python",
      },
      {
        title: "C/C++",
        icon: "fab fa-cuttlefish",
      },
      {
        title: "JavaScript",
        icon: "fab fa-js-square",
      },
    ],
  },
  {
    category: "Frontend",
    skills: [
      {
        title: "HTML",
        icon: "fab fa-html5",
      },
      {
        title: "CSS",
        icon: "fab fa-css3-alt",
      },
      {
        title: "React",
        icon: "fab fa-react",
      },
    ],
  },
  {
    category: "Backend",
    skills: [
      {
        title: "Django",
        icon: "fab fa-python",
      },
      {
        title: "Spring MVC",
        icon: "fab fa-java",
      },
      {
        title: "NodeJS",
        icon: "fab fa-node",
      },
    ],
  },
  {
    category: "Others",
    skills: [
      {
        title: "Docker",
        icon: "fab fa-docker",
      },
      {
        title: "Git",
        icon: "fab fa-git",
      },
    ],
  },
];

export const blogs = [
  {
    title: "What is a Version Control System ?",
    description:
      "A brief discussion and Introduction about the Version Control System, explaining it's features and working.",
    link: "https://www.codeplanet.co.in/post/what-is-a-version-control-system-all-about-version-control-git-github-gitlab-codeplanet",
    tags: ["Version Control System", "Git", "Github", "Gitlab"],
    cover: VcsImg,
  },
  {
    title: "The Beginner’s Dilemma of Learning Computer Science & Programming",
    description:
      "In this article I've shared some of my experiences about how a beginner should dive into the world of Computer Science and Programming.",
    link: "https://medium.com/@rawatdivyanshu/the-beginners-dilemma-of-learning-computer-science-programming-b27ce109eed7",
    tags: ["Beginner", "Computer Science"],
    cover: BcsImg,
  },
];

export const projects = [
  {
    title: "Path Finding Algorithms Visualizer",
    description:
      "A elegant ReactJS application to visualiZe different types of Path Finding Algorithms.",
    tag: "ReactJS",
    link: "https://github.com/rawat-divyanshu/Path-Finding-Algorithms-Visualizer",
    liveLink:
      "https://github.com/rawat-divyanshu/Path-Finding-Algorithms-Visualizer",
    cover: PathFindingImg,
  },
  {
    title: "Sorting Algorithms Visualizer",
    description:
      "A elegant ReactJS application to visualiZe different types of Sorting Algorithms.",
    tag: "ReactJS",
    link: "https://github.com/rawat-divyanshu/Sorting-Algorithms-Visualizer",
    liveLink:
      "https://rawat-divyanshu.github.io/Sorting-Algorithms-Visualizer/",
    cover: SortingImg,
  },
  {
    title: "MERN Blog Application",
    description: "A Full Stack Blog Application built using MERN stack.",
    tag: "MERN Full Stack",
    link: "https://github.com/rawat-divyanshu/MERN-Blog-Application",
    cover: BlogImg,
  },
  {
    title: "React Django E-Commerce Application",
    description:
      "A Full Stack E-Commerce Application built using React and Django RestFramework with integrated Payment Gateways.",
    tag: "React Django Full Stack",
    link: "https://github.com/rawat-divyanshu/django-ecommerce-backend",
    cover: EcommImg,
  },
  {
    title: "Loruki Responsive Website",
    description:
      "A responsive website for a fake cloud hosting platform Loruki built using HTML and CSS.",
    tag: "HTML CSS",
    link: "https://github.com/rawat-divyanshu/Loruki-Responsive-Website",
    liveLink: "https://rawat-divyanshu.github.io/Loruki-Responsive-Website/",
    cover: LorukiImg,
  },
  {
    title: "React Food Cart",
    description:
      "A simple and elegant ReactJS Food Cart application built using styled-components library with the functionalities to add items to cart, edit them, remove them and some other Food Items specific functionalities.",
    tag: "ReactJS",
    link: "https://github.com/rawat-divyanshu/React-Food-Cart",
    liveLink: "https://rawat-divyanshu.github.io/React-Food-Cart/",
    cover: ReactFoodCartImg,
  },
];

export const socialLinks = [
  {
    title: "Github",
    link: "https://github.com/rawat-divyanshu",
    icon: "fab fa-github",
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/in/rawat-divyanshu/",
    icon: "fab fa-linkedin",
  },
  {
    title: "Instagram",
    link: "https://www.instagram.com/awkward.humor/",
    icon: "fab fa-instagram-square",
  },
  {
    title: "Twitter",
    link: "https://twitter.com/_divyanshurawat",
    icon: "fab fa-twitter",
  },
  {
    title: "Facebook",
    link: "https://www.facebook.com/rawat.divyanshu19/",
    icon: "fab fa-facebook",
  },
  {
    title: "Medium",
    link: "https://medium.com/@rawatdivyanshu",
    icon: "fab fa-medium-m",
  },
];
