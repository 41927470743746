import React from "react";
import { blogs } from "../../data";

const Blogs = () => {
  return (
    <div id="blogs" className="container">
      <div className={`blogs-container grid blogs-grid`}>
        <h1 className="section-title">Blogs</h1>
        <div className="blogs-data-container">
          {blogs.map((blog, idx) => (
            <div className="blog-card">
              <div className="blog-img-container">
                <img src={blog.cover} alt={`blog-img-${idx}`} />
              </div>
              <div className="blog-card-body">
                <p class="blog-tag">{blog.tags[0]}</p>
                <h2 class="blog-title">
                  <a href={blog.link}>{blog.title}</a>
                </h2>
                <p class="blog-description">{blog.description}</p>
                <p class="blog-meta-info">
                  <span class="blog-author">Divyanshu Rawat</span>
                  <span class="blog-published-date">JUL 20, 2021</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
