import React from "react";
import { skills } from "../../data";

const Skills = () => {
  return (
    <div id="skills" className="container">
      <div className={`skills-container grid experience-grid`}>
        <h1 className="section-title">Skills</h1>
        <div className="skills-data-container">
          {skills.map((skillCategory, idx) => (
            <div key={idx} className="skill-category">
              <h2 className="skill-category-title">{skillCategory.category}</h2>
              <div className="skill-category-data">
                {skillCategory.skills.map((skill, skillIdx) => (
                  <div key={skillIdx} className="skill">
                    <i className={`${skill.icon} fa-8x text-gradient`}></i>
                    <h3 className="skill-name">{skill.title}</h3>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
