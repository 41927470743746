import "./App.css";
import Blogs from "./components/Blogs/Blogs";
import Contact from "./components/Contact/Contact";
import Experience from "./components/Experience/Experience";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import Projects from "./components/Projects/Projects";
import Skills from "./components/Skills/Skills";

function App() {
  return (
    <div className="wrapper">
      <main className="main-body">
        <Navbar />
        <Header />
        <Experience />
        <Skills />
        <Projects />
        <Blogs />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
