import React from "react";
import { projects } from "../../data";

const Projects = () => {
  return (
    <div id="projects" className="container">
      <div className={`projects-container grid projects-grid`}>
        <h1 className="section-title">Projects</h1>
        <div className="projects-data-wrapper">
          <div className="projects-data-container">
            {projects.map((project, idx) => (
              <div key={idx} className="project-card">
                <div className="project-img-container">
                  <img src={project.cover} alt={`project-img-${idx}`} />
                </div>
                <div className="project-card-body">
                  <p class="project-tag">{project.tag}</p>
                  <h2 class={`project-title text-gradient`}>{project.title}</h2>
                  <p class="project-description">{project.description}</p>
                  <p class="project-links">
                    {project.liveLink && (
                      <a className="live-link" href={project.liveLink}>
                        View Live
                      </a>
                    )}
                    <a className="github-link" href={project.link}>
                      View on Github
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <a href="https://github.com/rawat-divyanshu" className="github-btn">
            view more on github
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
