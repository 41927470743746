import React from "react";
import Avatar from "../../assets/images/avatar.jpg";
import Typewriter from "typewriter-effect";

const Header = () => {
  return (
    <section id="header" className="container">
      <div className={"header grid header-grid"}>
        <div className="img-container">
          <img src={Avatar} alt="avatar" />
        </div>
        <div className="text-container">
          <h2>
            Hello World!
            <br /> I'm Divyanshu Rawat
          </h2>
          <h1>
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("Full Stack Developer")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Frontend Developer")
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString("Backend Developer")
                  .pauseFor(2500)
                  .deleteAll()
                  .start();
              }}
            />
          </h1>
          <button className="contact-me-btn">Contact Me</button>
        </div>
      </div>
    </section>
  );
};

export default Header;
