import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar">
      <h1>DR.</h1>
      <div className="nav-links">
        <a href="#header">Home</a>
        <a href="#experience">About Me</a>
        <a href="#projects">Projects</a>
        <a href="#blogs">Blog</a>
        <a href="#contact">Contact</a>
      </div>
      <div className="nav-hamburger">
        <i className="fas fa-bars fa-2x text-gradient"></i>
      </div>
    </nav>
  );
};

export default Navbar;
