import React from "react";
import { socialLinks } from "./../../data";

const Footer = () => {
  return (
    <div className="container">
      <footer className="footer">
        <h1 className="footer-title"> Connect with Me</h1>
        <ul className="social-links">
          {socialLinks.map((link, idx) => (
            <li className="social-link" key={idx}>
              <a href={link.link}>
                <i className={link.icon}></i> {link.title}
              </a>
            </li>
          ))}
        </ul>
        <p style={{ color: "white" }}>
          Made with{" "}
          <span style={{ color: "red", fontSize: "1.5rem" }}>&hearts;</span> by
          Divyanshu Rawat
        </p>
      </footer>
    </div>
  );
};

export default Footer;
