import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="container">
      <div className={`contact-container grid contact-grid`}>
        <h1 className="section-title">Contact</h1>
        <div className="contact-data-container">
          <div className="phone-no-container contact-wrapper">
            <div>
              <i className="fas fa-phone-square-alt fa-3x text-gradient"></i>
            </div>
            <p>+91 9782078240</p>
            <p>+91 9649150740</p>
          </div>
          <div className="email-container contact-wrapper">
            <div>
              <i className="fas fa-envelope fa-3x text-gradient"></i>
            </div>
            <p>divyanshu.19r@gmail.com</p>
            <p>dev.divyanshu19@gmail.com</p>
          </div>
          <div className="address-container contact-wrapper">
            <div>
              <i className="fas fa-address-card fa-3x text-gradient"></i>
            </div>
            <p>
              71/210, Sector 7, Pratap Nagar, Sanganer Jaipur, Rajasthan, India
              <br />
              (Pin - 302033)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
