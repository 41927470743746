import React from "react";
import { experience } from "./../../data";

const Experience = () => {
  return (
    <div id="experience" className="container">
      <div className={`experience-container grid experience-grid`}>
        <h1 className="section-title">Experience</h1>
        <div className="experience-card-container">
          {experience.map((company) => (
            <div key={company} className={`experience-card card grid`}>
              <div className="circle-shape" />
              <div className="card-data">
                <h1 className={`company-title`}>
                  {company.companyName} -{" "}
                  <span className={`text-white fw-200`}>
                    {company.location}
                  </span>
                </h1>
                <h3 className={`position-time`}>
                  {company.position}, {company.time}
                </h3>
                <ul className="position-description">
                  {company.description.map((desc) => (
                    <li key={desc}>{desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
